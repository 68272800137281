<template>
  <div>
    <!-- start of hero -->   
    <section class="hero hero-style-2 hero-slider-wrapper">
        <div class="hero-slider">
            <div class="slide">
                <img src="assets/images/slider/slide-3.jpg" alt class="slider-bg">
                <div class="container">
                    <div class="row">
                        <div class="col col-md-8 col-sm-9 slide-caption">
                            <h2>We are your financial solution</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                            <div class="btns">
                                <a href="#" class="theme-btn">About us</a>
                                <a href="#" class="theme-btn-s2">Our Services</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="slide">
                <img src="assets/images/slider/slide-2.jpg" alt class="slider-bg">
                <div class="container">
                    <div class="row">
                        <div class="col col-md-8 col-sm-9 slide-caption">
                            <h2>We are your financial solution</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                            <div class="btns">
                                <a href="#" class="theme-btn">About us</a>
                                <a href="#" class="theme-btn-s2">Our Services</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="slide">
                <img src="assets/images/slider/slide-1.jpg" alt class="slider-bg">
                <div class="container">
                    <div class="row">
                        <div class="col col-md-8 col-sm-9 slide-caption">
                            <h2>We are your financial solution</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                            <div class="btns">
                                <a href="#" class="theme-btn">About us</a>
                                <a href="#" class="theme-btn-s2">Our Services</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- end of hero slider -->


    <!-- start services-section-s3 -->
    <section class="services-section-s3 section-padding">
        <div class="container">
            <div class="row">
                <div class="col col-md-8 col-md-offset-2">
                    <div class="section-title-s3">
                        <h2>Welcome to Top Finance</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12">
                    <div class="services-s2-grids">
                        <div class="grid">
                            <div class="inner">
                                <div class="icon">
                                    <i class="fi flaticon-balance"></i>
                                </div>
                                <div class="details">
                                    <h3><a href="#">Finance Planning</a></h3>
                                    <p>Pteur sint occaecat cupidatat non proi dent, sunt in culpa qu</p>
                                </div>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="inner">
                                <div class="icon">
                                    <i class="fi flaticon-wallet"></i>
                                </div>
                                <div class="details">
                                    <h3><a href="#">Wealth Management</a></h3>
                                    <p>Pteur sint occaecat cupidatat non proi dent, sunt in culpa qu</p>
                                </div>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="inner">
                                <div class="icon">
                                    <i class="fi flaticon-calculator"></i>
                                </div>
                                <div class="details">
                                    <h3><a href="#">Risk Analysis</a></h3>
                                    <p>Pteur sint occaecat cupidatat non proi dent, sunt in culpa qu</p>
                                </div>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="inner">
                                <div class="icon">
                                    <i class="fi flaticon-credit-card"></i>
                                </div>
                                <div class="details">
                                    <h3><a href="#">Venture Capitals</a></h3>
                                    <p>Pteur sint occaecat cupidatat non proi dent, sunt in culpa qu</p>
                                </div>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="inner">
                                <div class="icon">
                                    <i class="fi flaticon-computer"></i>
                                </div>
                                <div class="details">
                                    <h3><a href="#">Stock Market</a></h3>
                                    <p>Pteur sint occaecat cupidatat non proi dent, sunt in culpa qu</p>
                                </div>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="inner">
                                <div class="icon">
                                    <i class="fi flaticon-diamond"></i>
                                </div>
                                <div class="details">
                                    <h3><a href="#">Business Campaign</a></h3>
                                    <p>Pteur sint occaecat cupidatat non proi dent, sunt in culpa qu</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> <!-- end row -->
        </div> <!-- end container -->
    </section>        
    <!-- end services-section-s3 -->


    <!-- start about-section -->
    <section class="section-padding about-section-s3">
        <div class="container">
            <div class="row">
                <div class="col col-lg-8 col-md-10">
                    <div class="section-title-s2">
                        <h2>We deliver high performance with the clients’ projected satisfaction</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-md-7">
                    <div class="about-details">
                        <p>Unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem.</p>
                        <h3>Quality and reliability</h3>
                        <p>Aroident, sunt in culpa qui officia deserunt mollit anim id est laborum. Consect petur. Aroident, sunt in culpa qui officia deserunt mollit anim id est laborum. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    </div>
                </div>

                <div class="col col-md-5">
                    <div class="about-chart">
                        <img src="assets/images/about-chart-2.png" alt>
                    </div>
                </div>
            </div>
        </div> <!-- end container -->
    </section>
    <!-- end about-section -->


    <!-- start projects-section-s2 -->
    <section class="projects-section-s2 section-padding">
        <div class="container">
            <div class="row">
                <div class="col col-md-6">
                    <div class="projects-grid">
                        <div class="grid">
                            <div class="inner">
                                <div class="img-holder">
                                    <img src="assets/images/projects/img-1.jpg" alt>
                                </div>
                                <div class="details">
                                    <div class="details-inner">
                                        <ul>
                                            <li><a href="assets/images/projects/img-1.jpg" class="fancybox"><i class="fa fa-search"></i></a></li>
                                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                                        </ul>
                                        <h3>Pied Piper</h3>
                                        <p class="cat">startup</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="inner">
                                <div class="img-holder">
                                    <img src="assets/images/projects/img-2.jpg" alt>
                                </div>
                                <div class="details">
                                    <div class="details-inner">
                                        <ul>
                                            <li><a href="assets/images/projects/img-2.jpg" class="fancybox"><i class="fa fa-search"></i></a></li>
                                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                                        </ul>
                                        <h3>Pied Piper</h3>
                                        <p class="cat">startup</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="inner">
                                <div class="img-holder">
                                    <img src="assets/images/projects/img-3.jpg" alt>
                                </div>
                                <div class="details">
                                    <div class="details-inner">
                                        <ul>
                                            <li><a href="assets/images/projects/img-3.jpg" class="fancybox"><i class="fa fa-search"></i></a></li>
                                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                                        </ul>
                                        <h3>Pied Piper</h3>
                                        <p class="cat">startup</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="inner">
                                <div class="img-holder">
                                    <img src="assets/images/projects/img-4.jpg" alt>
                                </div>
                                <div class="details">
                                    <div class="details-inner">
                                        <ul>
                                            <li><a href="assets/images/projects/img-4.jpg" class="fancybox"><i class="fa fa-search"></i></a></li>
                                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                                        </ul>
                                        <h3>Pied Piper</h3>
                                        <p class="cat">startup</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="inner">
                                <div class="img-holder">
                                    <img src="assets/images/projects/img-5.jpg" alt>
                                </div>
                                <div class="details">
                                    <div class="details-inner">
                                        <ul>
                                            <li><a href="assets/images/projects/img-5.jpg" class="fancybox"><i class="fa fa-search"></i></a></li>
                                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                                        </ul>
                                        <h3>Pied Piper</h3>
                                        <p class="cat">startup</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="inner">
                                <div class="img-holder">
                                    <img src="assets/images/projects/img-6.jpg" alt>
                                </div>
                                <div class="details">
                                    <div class="details-inner">
                                        <ul>
                                            <li><a href="assets/images/projects/img-6.jpg" class="fancybox"><i class="fa fa-search"></i></a></li>
                                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                                        </ul>
                                        <h3>Pied Piper</h3>
                                        <p class="cat">startup</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> <!-- end col -->
                <div class="col col-md-6">
                    <div class="section-title-s5">
                        <h2>Our Best Works</h2>
                        <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                        <div class="skill-progress-bar">
                            <div class="skills">
                                <div class="skill">
                                    <h6>Finance</h6>
                                    <div class="progress">
                                        <div class="progress-bar" data-percent="85"></div>
                                    </div>
                                </div>
                                <div class="skill">
                                    <h6>Business</h6>
                                    <div class="progress">
                                        <div class="progress-bar" data-percent="98"></div>
                                    </div>
                                </div>
                                <div class="skill">
                                    <h6>Investment</h6>
                                    <div class="progress">
                                        <div class="progress-bar" data-percent="92"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <a href="#" class="more-work">find more of our works <i class="fa fa-angle-right"></i></a>
                    </div>
                </div>
            </div> <!-- end row -->
        </div> <!-- end container -->
    </section>
    <!-- end projects-section-s2 -->


    <!-- start fun-fact -->
    <section class="fun-fact">
        <div class="container">
            <div class="row start-count">
                <div class="col col-md-3 col-xs-6">
                    <div class="grid">
                        <div class="icon">
                            <i class="fi flaticon-handshake"></i>
                        </div>
                        <div class="info">
                            <h3 class="counter" data-count="1366">00</h3>
                            <span>Happy Clients</span>
                        </div>
                    </div>
                </div>
                <div class="col col-md-3 col-xs-6">
                    <div class="grid">
                        <div class="icon">
                            <i class="fi flaticon-presentation"></i>
                        </div>
                        <div class="info">
                            <h3 class="counter" data-count="4216">00</h3>
                            <span>Great Projects</span>
                        </div>
                    </div>
                </div>
                <div class="col col-md-3 col-xs-6">
                    <div class="grid">
                        <div class="icon">
                            <i class="fi flaticon-team"></i>
                        </div>
                        <div class="info">
                            <h3 class="counter" data-count="867">00</h3>
                            <span>Team Members</span>
                        </div>
                    </div>
                </div>
                <div class="col col-md-3 col-xs-6">
                    <div class="grid">
                        <div class="icon">
                            <i class="fi flaticon-internet"></i>
                        </div>
                        <div class="info">
                            <h3 class="counter" data-count="1366">00</h3>
                            <span>Happy Clients</span>
                        </div>
                    </div>
                </div>
            </div>
        </div> <!-- end container -->
    </section>
    <!-- end fun-fact -->


    <!-- start team-section -->
    <section class="team-section section-padding">
        <div class="container">
            <div class="row">
                <div class="col col-lg-3">
                    <div class="section-title-s5">
                        <h2>Our team</h2>
                        <p>Excepteur sint occaecat cupi datat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. nostrud exercitation.</p>
                        <a href="#" class="all-member">All Members <i class="fa fa-angle-right"></i></a>
                    </div>
                </div>
                <div class="col col-lg-9">
                    <div class="team-grids">
                        <div class="grid">
                            <div class="member-pic">
                                <img src="assets/images/team/img-1.jpg" alt>
                            </div>
                            <div class="member-info">
                                <h4>Kylo Ren</h4>
                                <span>Founder</span>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="member-pic">
                                <img src="assets/images/team/img-2.jpg" alt>
                            </div>
                            <div class="member-info">
                                <h4>Kylo Ren</h4>
                                <span>Founder</span>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="member-pic">
                                <img src="assets/images/team/img-3.jpg" alt>
                            </div>
                            <div class="member-info">
                                <h4>Kylo Ren</h4>
                                <span>Founder</span>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="member-pic">
                                <img src="assets/images/team/img-4.jpg" alt>
                            </div>
                            <div class="member-info">
                                <h4>Kylo Ren</h4>
                                <span>Founder</span>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="member-pic">
                                <img src="assets/images/team/img-5.jpg" alt>
                            </div>
                            <div class="member-info">
                                <h4>Kylo Ren</h4>
                                <span>Founder</span>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="member-pic">
                                <img src="assets/images/team/img-6.jpg" alt>
                            </div>
                            <div class="member-info">
                                <h4>Kylo Ren</h4>
                                <span>Founder</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div> <!-- end row -->
        </div> <!-- end container -->
    </section>
    <!-- end team-section -->


    <!-- start contact-section -->
    <section class="contact-section section-padding">
        <div class="container">
            <div class="row">
                <div class="col col-md-6">
                    <!-- <div class="contact-location-map" id="map"></div> -->
                </div>
                <div class="col col-md-6">
                    <div class="section-title-s4">
                        <h2>Request a call back</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipi scing elit, sed do eiusmod tempor incidi dunt ut labore et dolore magna aliqua.</p>
                    </div>
                    <div class="contact-form">
                        <form method="post" id="contact-form" class="form row contact-validation-active">
                            <div class="col col-sm-6">
                                <label for="name">Full Name</label>
                                <input type="text" class="form-control" id="name" name="name">
                            </div>
                            <div class="col col-sm-6">
                                <label for="email">Email</label>
                                <input type="email" class="form-control" id="email" name="email">
                            </div>
                            <div class="col col-sm-6">
                                <label for="phone">Phone No</label>
                                <input type="text" class="form-control" id="phone" name="phone">
                            </div>
                            <div class="col col-sm-6">
                                <label for="business">Business Type</label>
                                <select name="business" id="business" class="form-control">
                                    <option selected disabled>Select one</option>
                                    <option>Select number 1</option>
                                    <option>Select number 2</option>
                                    <option>Select number 3</option>
                                </select>
                            </div>
                            <div class="col col-xs-12 submit-btn">
                                <button type="submit">Submit</button>
                                <div id="loader">
                                    <i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
                                </div>
                            </div>
                            <div class="error-handling-messages">
                                <div id="success">Thank you</div>
                                <div id="error"> Error occurred while sending email. Please try again later. </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div> <!-- end row -->
        </div> <!-- end container -->
    </section>
    <!-- end contact-section -->


    <!-- start blog-section -->
    <section class="blog-section section-padding">
        <div class="container">
            <div class="row">
                <div class="col col-md-8 col-md-offset-2">
                    <div class="section-title-s3">
                        <h2>Recent News</h2>
                        <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </div>
                </div>
            </div>
            <div class="row news-grids">
                <div class="col col-md-4 col-xs-6">
                    <div class="grid">
                        <div class="entry-media">
                            <img src="assets/images/blog/img-1.jpg" alt>
                        </div>
                        <div class="entry-body">
                            <div class="entry-header">
                                <span class="date">16 Hours ago</span>
                                <h3><a href="#">Cut corporate taxes to boost growth</a></h3>
                            </div>
                            <div class="entry-details">
                                <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                            </div>
                            <div class="entry-meta">
                                <a href="#" class="read-more">Read More</a>
                                <a href="#" class="comments"><i class="fa fa-comments"></i> 10 Comments</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col col-md-4 col-xs-6">
                    <div class="grid">
                        <div class="entry-media">
                            <img src="assets/images/blog/img-2.jpg" alt>
                        </div>
                        <div class="entry-body">
                            <div class="entry-header">
                                <span class="date">16 Hours ago</span>
                                <h3><a href="#">European firms look to Canada to grow assets</a></h3>
                            </div>
                            <div class="entry-details">
                                <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                            </div>
                            <div class="entry-meta">
                                <a href="#" class="read-more">Read More</a>
                                <a href="#" class="comments"><i class="fa fa-comments"></i> 10 Comments</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col col-md-4 col-xs-6">
                    <div class="grid">
                        <div class="entry-media">
                            <img src="assets/images/blog/img-3.jpg" alt>
                        </div>
                        <div class="entry-body">
                            <div class="entry-header">
                                <span class="date">16 Hours ago</span>
                                <h3><a href="#">British drinkers leave Molson Coors thirsty</a></h3>
                            </div>
                            <div class="entry-details">
                                <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                            </div>
                            <div class="entry-meta">
                                <a href="#" class="read-more">Read More</a>
                                <a href="#" class="comments"><i class="fa fa-comments"></i> 10 Comments</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> <!-- end container -->
    </section>
    <!-- end blog-section -->


    <!-- start partners-section -->
    <section class="partners-section">
        <h2 class="hidden">Partners</h2>
        <div class="container">
            <div class="row">
                <div class="col col-xs-12">
                    <div class="partners-slider">
                        <div class="grid">
                            <img src="assets/images/partners/img-1.png" alt>
                        </div>
                        <div class="grid">
                            <img src="assets/images/partners/img-2.png" alt>
                        </div>
                        <div class="grid">
                            <img src="assets/images/partners/img-3.png" alt>
                        </div>
                        <div class="grid">
                            <img src="assets/images/partners/img-4.png" alt>
                        </div>
                        <div class="grid">
                            <img src="assets/images/partners/img-5.png" alt>
                        </div>
                        <div class="grid">
                            <img src="assets/images/partners/img-3.png" alt>
                        </div>
                    </div>
                </div>
            </div>
        </div> <!-- end container -->
    </section>
    <!-- end partners-section -->
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
    
  }
}
</script>








































































































































































































































import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    
  },
})
export default class App extends Vue {
  created() {
    var scripts = [
      "assets/js/jquery.min.js",
      "assets/js/bootstrap.min.js",
      "assets/js/jquery-plugin-collection.js",
      "https://maps.googleapis.com/maps/api/js?key",
      "assets/js/script.js"
    ];
    scripts.forEach(script => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
  }
}
